import React, { useEffect, useState } from 'react'
import { Paper, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../app/hooks';

import GlowButton from './bar/GlowButton'


const AboutPage = () => {
    const logged = useAppSelector((state) => state.loginstat.value)

    return (
        <Grid 
            justifycontent="center"
            alignitems="center"
        >
            <Grid item xs={12}>
                <Paper elevation={5} className="App-paper-full">
                    <Typography align="left" variant="body1">
                    At Brackett Innovations, we spearhead a revolutionary ecommerce platform meticulously designed to foster the growth and triumph 
                    of small businesses. Our mission is to alleviate the burden of initial startup costs by providing a complimentary platform for 
                    showcasing small enterprises. Through our innovative approach, we facilitate unparalleled opportunities for businesses to connect 
                    with a vast network of individuals and resources, propelling them towards the marketing, creation and development of groundbreaking 
                    products. Join us on this journey, where success knows no bounds. 
                    </Typography>
                </Paper>
                <Paper elevation={5} className="App-paper-full">
                    <Typography align="left" variant="h4">
                        Influencer Opportunities
                    </Typography>
                    <Typography align="left" variant="body1">
                        <p>
                            Welcome to Brackett Innovations – where your influence becomes your empire. In an era marked by the dynamic evolution of the 
                            internet and social media, influencing has emerged as a red-hot career path, evolving at a staggering pace. Statistics attest 
                            to the meteoric rise of this industry, with opportunities unfolding faster than ever.
                        </p>
                        <p>
                            At Brackett Innovations, we recognize the power and potential of influencers as trendsetters and tastemakers. We believe that 
                            influence is not just a profession; it's an art form. Our platform provides you with the canvas to showcase your social media 
                            prowess, turning your personal brand into a walking advertisement.
                        </p>
                        <p>
                            As a valued stakeholder, Brackett Innovations grants you a personalized page – a digital stage where you can curate and flaunt 
                            the products you influence. This isn't just about promotion; it's about building a narrative around the products that resonate 
                            with you. We believe in the ripple effect – by empowering influencers, we elevate not just the products they endorse, but the 
                            entire landscape of quality in the market.
                        </p>
                        <p>
                            Join us in this venture where your influence shapes industries, where your voice echoes beyond social media platforms, and where
                             your impact goes far beyond the screen. Brackett Innovations: Amplify Your Influence, Redefine Your Reach
                        </p>
                    </Typography>
                </Paper>
                <Paper elevation={5} className="App-paper-full">
                    <Typography align="left" variant="h4">
                        Business Opportunity
                    </Typography>
                    <Typography align="left" variant="body1">
                        Adding your business to Brackett Innovations is a straightforward and cost-free process. To ensure alignment with our platform standards, 
                        please adhere to the following requirements:
                    </Typography>
                    <ul>
                        <li>
                            Maintain consistent pricing: The price of each product on Brackett Innovations should mirror that listed on your official business website.
                        </li>
                        <li>
                            Define a finders fee: To incentivize influencers and encourage successful sales, your business should establish a clear and defined finders fee.
                        </li>
                    </ul>
                    {logged?
                        <GlowButton code={`/new/company`} name={'Add a business'}/>
                        // <Link to={`/new/company`} style={{ textDecoration: 'none'}}>
                        //     <Button
                        //       variant="contained"
                        //       sx={{ 
                        //         my: .5, 
                        //         color: '#E3F1E9', 
                        //         display: 'block',
                        //         marginRight: 'auto',
                        //         marginLeft: 'auto', 
                        //         boxShadow: `-30px 0px 16px -10px #9BCDB0,30px 0px 16px -10px #9BCDB0`,
                        //         '&:hover': {
                        //           boxShadow: `-30px 0px 16px -10px #E3F1E9,30px 0px 16px -10px #E3F1E9`,
                        //           backgroundColor: 'black',
                        //         },
                        //       }}
                        //     >
                        //       Add a business
                        //     </Button>
                        //   </Link>
                        :
                            <Typography align="left" variant="body1">
                                Access your account to seamlessly integrate a product you love or a small Business of yourown into the 
                                product listing on Brackett Innovations website
                            </Typography>
                    }
                </Paper>
                <Paper elevation={5} className="App-paper-full">
                    <Typography align="left" variant="h4">
                        Contact Us
                    </Typography>
                    <Typography align="left" variant="body1">
                        We welcome you to reach out to us for any inquiries, feedback, or concerns. Your insights are valuable to us, 
                        and we appreciate your feedback. We will make every effort to respond to your communication promptly
                    </Typography>
                    <Typography align="left" variant="body1">
                        Email: brackettinnovations.feedback@gmail.com
                    </Typography>
                </Paper>
            </Grid>

        </Grid>  
    )
}


export default AboutPage