import React from 'react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom'
import { useAppDispatch} from '../../app/hooks';
import { uploadCartItem } from '../../features/cartlist';

const CartIcon = ({cart_number}) => {
  const dispatch = useAppDispatch()

  const clearMerch =  async  () => {
    dispatch(uploadCartItem({}));
  };

  return (
    <Tooltip title="Cart">
        <Link to='/cart' style={{ textDecoration: 'none' }}>
            <IconButton color="inherit">
                <Badge badgeContent={cart_number} color="secondary">
                <Avatar alt="Cart">
                    <ShoppingCartIcon aria-label="Cart" color="secondary" onClick={() => {clearMerch()}}/>
                </Avatar>
                </Badge>
            </IconButton>
        </Link>
    </Tooltip>
  )
}

export default CartIcon