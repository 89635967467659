import { configureStore } from '@reduxjs/toolkit';
import loginstatReducer from '../features/login';
import loaderReducer from '../features/loading';
// import thunk from 'redux-thunk';
import histcompanyReducer from '../features/histcompany'
import viewcompanyReducer from '../features/viewcompany'
import viewproductReducer from '../features/viewproduct'
import followinfluencerReducer from '../features/followinfluencer'
import cartlistReducer from '../features/cartlist'
// import { apiSlice } from '../features/dogs/dogs-api-slice';

// Function to save state to localStorage
function saveState(state) {
  try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('appState', serializedState);
  } catch (e) {
      console.warn('Could not save state', e);
  }
}

// Function to load state from localStorage
function loadState() {
  try {
      const serializedState = localStorage.getItem('appState');
      if (serializedState === null) {
          return undefined;
      }
      return JSON.parse(serializedState);
  } catch (e) {
      console.warn('Could not load state', e);
      return undefined;
  }
}

// Load the persisted state from localStorage
const persistedState = loadState();

export const store = configureStore({
  reducer: {
    loader: loaderReducer,
    loginstat: loginstatReducer,
    viewcompany: viewcompanyReducer,
    viewproduct: viewproductReducer,
    histcompany: histcompanyReducer,
    followinfluencer: followinfluencerReducer,
    cartlist: cartlistReducer,
    // [apiSlice.reducerPath]: apiSlice.reducer,
  },
  preloadedState: persistedState,// Preload the state with persisted data
  // middleware: (getDefaultMiddleware) => {
  //   return getDefaultMiddleware().concat(thunk);
  // },
});

// Subscribe to store changes and save the state to localStorage
store.subscribe(() => {
  saveState(store.getState());
});