import React from 'react';
import Template0Component from './Template0Component';
import Template1Component from './Template1Component';
import Template2Component from './Template2Component';
import { useAppSelector } from '../../app/hooks';


const AppBar = ({ templateId }) => {
  const showCompany  = useAppSelector((state) => state.viewcompany)
  
  return (
    <div>
      {showCompany.template_id === 0 && <Template0Component />}
      {showCompany.template_id === 1 && <Template1Component />}
      {showCompany.template_id === 2 && <Template2Component />}
    </div>
  );
};

export default AppBar;