
import React,{useState} from 'react'
import PasswordIcon from '@mui/icons-material/Password';
import { Paper, Grid, Container } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import {useForm} from 'react-hook-form'
import apit from '../api/authApi';
// import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
          },
        display: "block"
        },
    iconcol: {
        backgroundColor: "green",
    },
    paper: {
        backgroundCoor: 'red',
    },
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }

})

const TextEditor = ({ key, value, id, cart_id, cartaddon_id, updateCarts}) => {
    const classes = useStyles()
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();
    // const { register, handleSubmit, reset } = useForm();
    const [showDanger,setShowDanger]=useState(false)
    const [openNameEdit,setOpenNameEdit]=useState(false)
    const [serverResponse,setServerResponse]=useState('')

    const handleEditProfileOpen = () => {
        setOpenNameEdit(true);
    };

    const EditProfile = (data) => {
        //     setEditProfileOpen(false);
            console.log(data)
            const body = {
                    content: data.content,
                    mediaslice_id: id
                }
    
            apit.put(`/api/order/cart/mediaslice`,body).then(data => {
                updateCarts()
                setOpenNameEdit(false);
            })
        }

    const handleEditProfileClose = () => {
        setOpenNameEdit(false);
    };

    return (
        <Grid 
        container
        // direction="row-reverse"
        justify="center"
        alignitems="center"
        >
            <Grid item xs={9} display="flex" alignItems="center">
            <Typography variant="subtitle1" ml='10px' textAlign="left">Text: {value}</Typography>
            </Grid>
                
            
            <br></br>
            <Grid item xs={3}>
                <Button
                    onClick={()=>{setOpenNameEdit(true)}}
                    // onClick={deleteWish}
                    variant={"outlined"}
                    sx={{ 
                    my: .5, 
                    display: 'block',
                    marginRight: 'auto',
                    width: '90%', 
                    marginLeft: 'auto',    
                    }}
                >
                    Edit
                </Button>
            </Grid>
            <Dialog 
                    open={openNameEdit}
                    onClose={handleEditProfileClose}
                    maxWidth='xs'
                    fullWidth
                >
                    <DialogTitle>Update Name</DialogTitle>
                   <form 
                        noValidate 
                        autoComplete="off"
                    >
                    <DialogContent>
                        <FormGroup
                            className={classes.errorRoot}
                        >
                            <TextField
                                type="text"
                                {...register("content", { required: true, maxLength: 250 })}
                                className={classes.field}
                                label="Content"
                                required
                                multiline
                                rows={3}
                                defaultValue={value}
                                error={errors.content}
                            />
                            <div
                                className={classes.errorMsg}
                            >
                                {errors.content?.type === "required" && <p style={{ color: "red" }}><small>Content is required</small></p>}
                                {errors.contnet?.type === "maxLength" && <p style={{ color: "red" }}><small>Content must be less than 250 characters</small></p>}
                            </div> 
                            
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={handleEditProfileClose}
                            className={classes.btn}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="contained"
                            onClick={handleSubmit(EditProfile)}
                            className={classes.btn}
                        >
                            Update
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>  
        </Grid>
    )
}

export default TextEditor