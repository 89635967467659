import { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import api from '../api/companies';
import Selections from './Selections'
import MerchandiceCheckbox from './MerchandiceCheckbox'

const useStyles = makeStyles({
    root: {
        // component default is "inline-flex", using "flex" makes the
        // label + control group use the entire width of the parent element
        display: "flex",
        // component default is "flex-start", using "space-between" pushes
        // both flexed content to the right and left edges of the flexbox
        // Note: the content is aligned to the right by default because
        // the 'labelPlacement="start"' component prop changes the flexbox
        // direction to "row-reverse"
        justifyContent: "space-between",
    },
})

// const label = { inputProps: { 'aria-label': 'Checkboxgift' } };

const ProductSelector = ({ merch, color, getSelectedMerch}) => {
    const [checkedIds, setCheckedIds] = useState([]);
    const [selectionsCombo, setSelectionsCombo] = useState([]);
    const [attributes, setAttributes] = useState([])
    const classes = useStyles()

    useEffect(()=> {
        getProductAttributes();
    },[merch]);

    const getProductAttributes = async () => {
        try {
            const response = await api.get(`/api/order/attribute/merchandice/${merch.merch_id}`).then(data => {
                setAttributes(data.data)
                })
                .catch(error => console.log(error));
        } catch (err) {
            console.log(err)
        }
    }


    const handleChange = (index, value, attribute) => {
        // the selections left and what is their quantity so you can default ones with 0 quantity
        getSelectedMerch(value, attribute, merch.merch_id,merch.manufactured_output);
      };

    const handleMerchClick = (id) => {
        if (checkedIds.includes(id)) {
          setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
        } else {
          setCheckedIds([...checkedIds, id]);
        }
      };
    

    return (
        <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
        >
            {
                attributes.map(
                    (attribute,index)=>(
                        <Grid item xs={12}>
                            
                            <FormControl labelPlacement="start" sx={{width:'100%'}}>
                                <Grid item alignitems="left" xs={12} sx={{ pl:'2px'}}>    
                                    <FormLabel 
                                    labelPlacement="start" 
                                    classes={classes}
                                    id={attribute.name_code}
                                    sx={{ pl:'2px', color: color }}
                                    >
                                        {attribute.name_view} </FormLabel>
                                </Grid>
                                <Grid item xs={12} sx={{ pl:'7px'}}>
                                    <Selections
                                        key={attribute.id}
                                        attribute_id={attribute.id} 
                                        setSelection={(value)=>{handleChange(index,value,attribute.id)}}
                                        req={attribute.req}
                                        name_code={attribute.name_code}
                                        init_value={attribute.default_selection_id} 
                                    />
                                </Grid>
                            </FormControl>
                            
                        </Grid>
                    )
                )
            }
            <>
            {

                <Grid item xs={12}>
                    
                    <MerchandiceCheckbox
                        merch={merch}
                        checked={true}
                        // setChecked={}
                        onMerchClick={(id) => handleMerchClick(id)}
                        label={{ inputProps: { 'aria-label': 'Checkbox' } }}
                        color={color}
                    />
                    
                </Grid>
            }
            </>
    
        </Grid>
    )
}


export default ProductSelector