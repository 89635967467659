import Keycloak from "keycloak-js";
import apit from  '../components/api/authApi';
// import { Cookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { stakeholderLogin, stakeholderLogout } from '../features/login';


// const _kc = new Keycloak({
//     url: 'http://localhost:8080',
//     realm: 'bi-app',
//     clientId: 'biserver'
// });
// const _kc = new Keycloak('/keycloak.json');
// let initOptions = {
//   url: 'http://localhost:8080/',
//   realm: 'bi-app',
//   clientId: 'bipublic',
// }

let initOptions = {
  "realm": "biapp",
  // "auth-server-url": "http://localhost:8080",
  "auth-server-url": "https://www.brackettinnovations.com/auth",
  // "url": process.env.REACT_APP_KEYCLOAK_URL,
  // "url": "http://localhost:8080",
  "url": "https://www.brackettinnovations.com/auth",
  "ssl-required": "external",
  "clientId": "bipublic",
  "public-client": true,
  "confidential-port": 0,
  "enable-cors": true
}

const _kc = new Keycloak(initOptions);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
// const dispatch = useAppDispatch()


function getCookie(name) {
      const value = `; ${document.cookie}`;
      // const parts = value.split(`; ${name}=`);
      // if (parts.length === 2) return parts.pop().split(';').shift();
      console.log(value)
  }
let isInitialized = false;
_kc.onAuthSuccess = () => console.log('Authenticated!');
_kc.onAuthError = () => console.log('Error!');
_kc.onTokenExpired = () => console.log('Exspired Token!');
_kc.onAuthRefreshError = () => console.log('Refreash Error');
_kc.onAuthError = () => console.log('Error!');

const initKeycloak = (onAuthenticatedCallback) => {
  // const dispatch = useAppDispatch();
  if (_kc.isLoddedIn == null){
    console.log('No loggedon data but already initiaized')
    _kc.clearToken()
    const keycloakIdentityLegacy = getCookie('KEYCLOAK_IDENTITY_LEGACY');
    onAuthenticatedCallback(false)
  }
  if (!isInitialized && !_kc.isLoggedIn) {
    _kc.init({
      onLoad: 'check-sso', // Supported values: 'check-sso' , 'login-required'
    }).then((auth) => {
      if (!auth) {
        console.log("failed to authenticate")

      } else {
        console.log(auth)
        // apit.defaults.headers.common['Authorization'] = 'TESTIG';
        _kc.updateToken(30).then(refreshed => {
          if (refreshed) {
            onAuthenticatedCallback(true)
          }
        }).catch(() => {
          console.error('Failed to refresh token');
          onAuthenticatedCallback(false)
          _kc.logout()

        });       
        onAuthenticatedCallback(true)
        
      }
    }, (error) => {
      /* Notify the user if necessary */
      console.error("Authentication Failed" + error);
      onAuthenticatedCallback(false)
    });
    // _kc.init({
    //   onLoad: 'check-sso',
    //   // silentCheckSsoRedirectUri: window.locat v ion.origin + '/silent-check-sso.html',

  } else if (!_kc.isLoggedIn) {
    onAuthenticatedCallback(false)

  } else {
    onAuthenticatedCallback(true)
  }
    isInitialized=true;
};

// const doLogin = () => 
//   _kc.login().then(res => res.json()).then(data => console.log(data)).catch(error => console.log(error));
//   ; 
const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;
const isAuthenticated = () => _kc.authenticated

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  isAuthenticated,
  getToken,
  updateToken,
  getUsername,
  hasRole,
};

export default UserService;
// /**
//  * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
//  *
//  * @param onAuthenticatedCallback
//  */

// let isInitialized = false;

// const initKeycloak = (onAuthenticatedCallback) => {
//   if (!isLoggedIn) {
//     console.log("user is not authenticated..!");
//     _kc.init({
//       onLoad: 'login-required',
//       silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
//       pkceMethod: 'S256',
//     })
//       .then((authenticated) => {
//         console.log('User authentication status:', authenticated);
//         console.log('Keycloak token:', _kc.token);
//         if (!authenticated) {
//           console.log("user is not authenticated..!");
//         } else {
//           /* Remove below logs if you are using this on production */
//           console.info("Authenticated");
//           console.log('auth', authenticated)
//           console.log('Keycloak', _kc)
//           console.log('Access Token', _kc.token)
      
//           /* http client will use this header in every request it sends */
//           // httpClient.defaults.headers.common['Authorization'] = `Bearer ${kc.token}`;
      
//           _kc.onTokenExpired = () => {
//             console.log('token expired')
//           }
//         }
//         onAuthenticatedCallback();
//       })
//       .catch(console.error);

//     isInitialized = true;
     
//   }
// };

// const doLogin = _kc.login;

// const doLogout = _kc.logout;

// const getToken = () => _kc.token;

// const getTokenParsed = () => _kc.tokenParsed;

// const isLoggedIn = () => _kc.authenticated;

// const updateToken = (successCallback) =>
//   _kc.updateToken(5)
//     .then(successCallback)
//     .catch(doLogin);

// const getUsername = () => _kc.tokenParsed?.preferred_username;

// const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

// const UserService = {
//   initKeycloak,
//   doLogin,
//   doLogout,
//   isLoggedIn,
//   getToken,
//   getTokenParsed,
//   updateToken,
//   getUsername,
//   hasRole,
// };

// export default UserService;