import React from 'react'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'


const GlowButton = ({name, disable, code, primary_color='#9BCDB0',mr='auto',secondary_color='#E3F1E9', view=true}) => {
  return (
    <Link to={code} style={{ textDecoration: 'none' }} >
      {view ?
        <Button
          disabled={disable}
          variant="contained"
          sx={{ 
            my: .5, 
            color: '#E3F1E9', 
            display: 'block',
            marginRight: mr,
            marginLeft: 'auto', 
            boxShadow: `-30px 0px 16px -10px ${primary_color},30px 0px 16px -10px ${primary_color}`,
            '&:hover': {
              boxShadow: `-30px 0px 16px -10px ${secondary_color},30px 0px 16px -10px ${secondary_color}`,
              backgroundColor: 'black',
            },
          }}
        >
          {name}
        </Button>
      :
       <></>
      }
    </Link>
  )
}

export default GlowButton;