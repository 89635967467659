import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const accountSettings = [{name: 'Profile', code: '/profile'},{name: 'Logout', code: '/'}]

const StakeholderIcon = ({updateLogin, profile_picture}) => {
    const [anchorElUserSm, setAnchorElUserSm] = React.useState(null);


    const handleOpenUserMenuSm = (event) => {
        setAnchorElUserSm(event.currentTarget);
    };

    const handleCloseUserMenuSm = () => {
        setAnchorElUserSm(null);
    };

    const userAction = async (action) => {
        if (action==="Logout") {
        //   await UserService.doLogout()
          // deleteRoles()
          updateLogin('Logout')
          // setLogged(UserService.isLoggedIn());
        }
      };


    return (
        <div>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Tooltip title="Account">
                <IconButton id="menu-appbarmd" onClick={handleOpenUserMenuSm}>
                        <Avatar 
                            alt="Account" 
                            src={process.env.PUBLIC_URL + '/img' + profile_picture}
                            size="large"
                        />
                    </IconButton>
            </Tooltip>
            </Box>
            <Box 
              sx={{ display: { xs: 'block', md: 'none' }}}>
                <Tooltip title="Account">
                    <IconButton id="menu-appbarmd" onClick={handleOpenUserMenuSm}>
                            <Avatar 
                                alt="Account" 
                                src={process.env.PUBLIC_URL + '/img' + profile_picture}
                                sx={{ width: 60, height: 60 }}
                            />
                        </IconButton>
                </Tooltip>
            </Box>
            <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar-md"
            anchorEl={anchorElUserSm}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right' ,
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right' ,
            }}
            open={Boolean(anchorElUserSm)}
            onClose={handleCloseUserMenuSm}
            >
            {accountSettings.map(({name, code}) => (
                <Link key={name} to={code} style={{ textDecoration: 'none' }}>
                    <MenuItem 
                    key={name}
                    value={code}
                    onClick={(event)=>{handleCloseUserMenuSm(event); userAction(name)}}
                    >
                    <Typography textAlign="center">{name}</Typography>
                    </MenuItem>
                </Link>
                ))}
            </Menu>
        </div>
    )
}

export default StakeholderIcon