import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { fetchCompanyData } from '../../features/viewcompany';
import { useAppDispatch } from '../../app/hooks';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    color: 'inheret',
  },
})

const FootBar = ({SelectCompany}) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()


  return (
    // <AppBar position="static">
      <Container className={classes.root}>
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'flex', md: 'flex' }, mr: 1,flexGrow: 1 }}>
          <Typography
              variant="h6"
              style={{ wordWrap: "break-word" }}
              component="a"
              href="/"
              onClick={()=>dispatch(fetchCompanyData(0))}
              textAlign="center"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.1.5rem',
                color: 'inherit',
                textDecoration: 'none',
                lineHeight: '1.2',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              Brackett Innovations
            </Typography>
          </Box>
        </Toolbar>
      </Container>
    // </AppBar>
  );
};
export default FootBar;