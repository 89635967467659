import { useEffect, useState } from 'react'
import { Paper, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import ProductBuyPage from './ProductBuyPage';

import { fetchProductData } from '../../features/viewproduct';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import api from '../api/companies';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block",
        maxWidth: '120px'
        },
    labelPlacementStart: {
        justifyContent: 'space-between'
        },
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        marginRight: '.5em',
    },
    navlogolg: {
        color: '#9BCDB0',
        maxWidth: '230px',
        marginRight: '.5em',
        maxHeight: '250px',
      },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
    root: {
        // component default is "inline-flex", using "flex" makes the
        // label + control group use the entire width of the parent element
        display: "flex",
        // component default is "flex-start", using "space-between" pushes
        // both flexed content to the right and left edges of the flexbox
        // Note: the content is aligned to the right by default because
        // the 'labelPlacement="start"' component prop changes the flexbox
        // direction to "row-reverse"
        justifyContent: "space-between",
    },
})

const label = { inputProps: { 'aria-label': 'Checkboxgift' } };

const ProductPage = () => {
    const [loadingData, setLoadingData] = useState(true);                                              
    const viewProduct  = useAppSelector((state) => state.viewproduct)
    const showCompany  = useAppSelector((state) => state.viewcompany)
    const followUsername = useAppSelector((state) => state.followinfluencer.username)
    

    const classes = useStyles()

    useEffect(()=> {
        setLoadingData(false)

      },[]);



    return (
        <Box> 
            <Paper className="App-paper-full">
                <Grid item xs={12}>
                    <Typography textAlign="center" variant="subtitle">Influencer: {followUsername}</Typography>  
                </Grid>
            </Paper>
            <Paper elevation={5} className="App-paper-full">
                
                <form>
                    {!loadingData ?            
                        <Grid 
                            container
                            // direction="row-reverse"
                            justify="center"
                            alignitems="center"
                        >
                            <Grid item xs={12} sx={{border:`solid ${showCompany.primary_color}`, borderWidth: .5, borderRadius: 2}}>
                                {viewProduct.name_view}
                            </Grid>
                            <Grid item xs={12} sx={{padding: 0, margin: 0}}>
                                <img src={process.env.PUBLIC_URL + '/img' + viewProduct.picture_location} className={classes.navlogolg} alt="Logo" />
                            </Grid>
                        </Grid>
                    :
                    <>
                    loading...
                    </>
                }
                </form>
            </Paper>
            <ProductBuyPage/>
        </Box>
    )
}


export default ProductPage;