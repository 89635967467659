import React from 'react';
import { useAppSelector } from '../../app/hooks';

const Template2Component  = ({ templateId }) => {
  const lastCompany  = useAppSelector((state) => state.histcompany)

  return (
    <div>
      template 2
      <div>{lastCompany.name_view}</div>
      </div>
  );
};

export default Template2Component;