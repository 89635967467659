import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox'

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block"
        },
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        marginRight: '.5em',
    },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
})

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const WishMerchandice = ({picture,total,description,name,quantity,influencer,checked,company,primary_color,title,changeClick}) => {
    const [active, setActive] = useState(checked);
    const classes = useStyles()

    useEffect(()=> {
        setActive(checked)
    },[checked]);

    const changeClicked = () => {
        changeClick(!active)
    }

    return (
        <Grid 
            container
            // direction="row-reverse"
            justify="center"
            alignitems="center"
        >
            <Grid item xs={1} sx={{ paddingRight: '.5em' }} display="flex" alignItems={"center"}>
                <Box>
                    <Checkbox {...label}  onClick={()=>(changeClicked())} defaultChecked={active} checked={active? true: false}/>
                </Box>
            </Grid>
            <Grid item xs={3} md={2} display="flex" alignItems={"center"}>
                <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                >
                    <Grid item xs={12} display="flex" alignItems={"center"}>
                        <Box>
                            <img 
                                src={process.env.PUBLIC_URL + '/img' + picture}
                                className={classes.productimage}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={8} md={7} display="flex" alignItems={"center"}>
                <Grid 
                        container
                        // direction="row-reverse"
                        justify="center"
                        alignitems="left"
                    >
                    <Grid item xs={12} sx={{ paddingLeft: '.5em' }} display="flex" alignItems={"left"}>
                        <Typography textAlign="left" variant="title">{title}</Typography>
                    </Grid>    
                    {company ?
                        <Grid item xs={12} display="flex"  sx={{ paddingLeft: '.5em' }} alignItems={"left"}>
                            <Typography sx={{ width: '100%'}} textAlign="left">
                               Company: {company}
                            </Typography>
                        </Grid>
                    :
                        <></>
                    }
                    {name ?
                        <Grid item xs={12} display="flex"  sx={{ paddingLeft: '.5em' }} alignItems={"left"}>
                            <Typography sx={{ color: primary_color, width: '100%'}} textAlign="left">
                               Name: {name}
                            </Typography>
                        </Grid>
                    :
                        <></>
                    }
                    
                    {influencer?
                        <Grid item xs={12}  sx={{ paddingLeft: '.5em' }} display="flex" alignItems={"left"}>
                            <Typography textAlign="left" sx={{ width: '100%'}}>Influencer: {influencer}</Typography>
                        </Grid>
                    :
                        <div></div>
                    } 
                    <Grid item xs={12} sx={{ paddingLeft: '.5em' }} display="flex" alignItems={"left"}>
                        <Typography textAlign="left">{description}</Typography>
                    </Grid> 
                    <Grid item xs={12} sx={{ paddingLeft: '.5em' }} alignItems={"left"}>
                        <Typography textAlign="left">
                            Quantity: {quantity}
                        </Typography>
                    </Grid>
                     <Grid item xs={12}  sx={{ paddingLeft: '.5em' }} alignItems={"left"}>
                        <Typography textAlign="left" variant="h5">Total: ${total}</Typography>
                    </Grid>
                   
                   
                    
                    {/* { xs: 'block', md: 'none' } */}
                </Grid>
            </Grid>


            <br></br>
        </Grid>
    )
}


export default WishMerchandice