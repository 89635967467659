import { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import api from '../api/companies';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  navlogolg: {
      color: '#9BCDB0',
      width: '100%',
      marginRight: '.5em',
  },
  navlogosm: {
    color: '#9BCDB0',
    width: '70px',
    marginRight: '.5em',
},
})

const Selections = ({setSelection, init_value,attribute_id, name_code, key=attribute_id,req=false}) => {
    const [value, setValue] = useState(init_value)
    const [selections, setSelections] = useState([])
    const classes = useStyles()

    useEffect(()=> {
        setSelection(value)
      },[]);

    useEffect(()=> {
        const getAttributeSelections = async () => {
        
            try {
                const response = await api.get(`/api/order/selection/attribute/${attribute_id}`).then(data => {
                    setSelections(data.data)
                    })
                    .catch(error => console.log(error));
            } catch (err) {
                console.log(err)
            }
        }
        getAttributeSelections();
      },[attribute_id]);

    const handleChange = (event) => {
        setValue(event.target.value);
        setSelection(event.target.value)
      };


    return (
            <RadioGroup
                row
                aria-labelledby={{name_code} + '-label'}
                name={name_code}
                key={key}
                value={value}
                onChange={handleChange}
                disabled={req}
            >
                {
                    selections.map(
                        (selection,index)=>(
                            <Grid item>
                                <FormControlLabel value={selection.id} control={<Radio />} label={selection.name_view}/>
                                {selection.picture ?
                                    <Box sx={{width: 'auto',height: 'auto',alignSelf: 'center'}}>
                                        <img src={process.env.PUBLIC_URL + '/img/' + selection.picture} className={classes.navlogosm}/>
                                    </Box>
                                :
                                    ""
                                }
                                
                            </Grid>
                        )
                    )
                }
            </RadioGroup>
                
    )
}


export default Selections