import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  navlogolg: {
      color: '#9BCDB0',
      width: '100%',
      marginRight: '.5em',
  },
  navlogomd: {
    color: '#9BCDB0',
    width: '70px',
    marginRight: '.5em',
},
})

const DisplayName = ({name}) => {
  const classes = useStyles()
  const [template, setTemplate] = React.useState(1)
  const [nameView, setNameView] = React.useState([name])


React.useEffect(()=> {
    if (name == "Brackett Innovations") {
      setNameView(["Brackett","Innovations"])
      setTemplate(2)      
    } else {
      setNameView([name])
    }

  },[name]);

  return (
    <div style={{display: 'flex'}}>
        {template==1 ?
          <Box sx={{
                display: { xs: 'none', md: 'inline-block' },
              }}>
            <Typography
              variant="h6"
              style={{ wordWrap: "break-word" }}
              component="a"
              // href="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.1.5rem',
                color: 'inherit',
                textDecoration: 'none',
                lineHeight: '1.2',
              }}
            >
              {nameView[0]}
            </Typography>
          </Box>
        :
          <Box sx={{
            display: { xs: 'none', md: 'inline-block' },
          }}>
            <Typography
              variant="h6"
              style={{ wordWrap: "break-word" }}
              component="a"
              // href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.1.5rem',
                color: 'inherit',
                textDecoration: 'none',
                lineHeight: '1.2',
              }}
            >
              {nameView[0]}
            </Typography>
          
          <Typography
            variant="h6"
            style={{ wordWrap: "break-word" }}
            component="a"
            // href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.1.5rem',
              color: 'inherit',
              textDecoration: 'none',
              lineHeight: '1.2',
            }}
          >
            {nameView[1]}
          </Typography>
        </Box>
      }
    </div>
  )
}

export default DisplayName