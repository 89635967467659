import { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import Credit from './Credit'
import AddressInput from './AddressInput'
import SmallView from './SmallView'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import DialogTitle from '@mui/material/DialogTitle';
import { Paper, Grid } from '@mui/material';
import apit from '../api/authApi';
import { useAppSelector } from '../../app/hooks';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  badgeimage: {
    backgroundColor: '#282c34',
},
})

const LoggedInHome=()=>{
    const [addresses, setAddresses] = useState([]);
    const [addressSelected, setAddressSelected] = useState(false);
    const [openAddress, setOpenAddress] = useState(false);
    const [wishes, setWishes] = useState([]);
    const [openBuy, setOpenBuy] = useState(false);
    const [address, setAddress] = useState('');
    const [total, setTotal] = useState('');
    const [orderRecived, setOrderRecived] = useState(null);
    const classes = useStyles()
    const [adr, setAdr] = useState(null);

    useEffect(()=> {

        apit.get(`/api/order/address`).then(data => {
            setAddresses(data.data)
            if (data.data.length > 0) {
                setAddressSelected(true)
                setAdr(data.data[data.data.length-1].id)
                setAddress(data.data[data.data.length-1])
            }
            
        })

        apit.get(`/api/order/carts/checkout`).then(data => {
            setWishes(data.data)
            let sum = 0
            for (let i in data.data) {
                sum=data.data[i].price_usd + sum
            }
            setTotal(sum)
        })

    },[]);


    const handleSubmit = () => {
        console.log('submit orders')
        setOpenBuy(true)
    }

    const handleClose = () => {   
        setOpenBuy(false)
    }

    const handleOrder = (id) => {   
        setOpenBuy(false)
        console.log(id)
        setOrderRecived(id)

    }

    const handleChangeAddress = (event) => {
        setAdr(event.target.value);
        setAddressSelected(true)
        setAddress(addresses.find(x => x.id === event.target.value))
      };

    const handleCloseAddress = () => {   
        setOpenAddress(false)
    }

    const newAddress = (id) => {
        setAdr(id)
        setOpenAddress(false)
        setAddressSelected(true)
        apit.get(`/api/order/address`).then(data => {
            setAddresses(data.data)
            setAddress(data.data[data.data.length-1])
        })
    }


    return (
        <div>
            {orderRecived?
                <Paper elevation={5} className="App-paper-full">
                    <Grid 
                        container
                        // direction="row-reverse"
                        justifycontent="center"
                        alignitems="center"
                    >
                        <Grid item xs={12} justifycontent="center">
                        <Typography textAlign="center">Your order has been received: order id ({orderRecived})</Typography>
                        </Grid>
                        <Grid item xs={12} justifycontent="center">

                            <Box sx={{ display: 'flex',
                                width: '100%', margin: 'auto'}} alignitems="center" justifyContent="center" display="flex">
                            <Avatar 
                                src={process.env.PUBLIC_URL + '/img/badges/influencer_light.png'}
                                sx={{ width: 150, height: 150 }}
                                className={classes.badgeimage}
                            />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            :
                <Paper elevation={5} className="App-paper-full">
                    <Grid 
                        container
                        // direction="row-reverse"
                        justifycontent="center"
                        alignitems="center"
                    >
                        <Grid item xs={12}>
                            <Typography variant="h3" textAlign="center">Checkout</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" textAlign="left">1 Review Product Total</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                
                                wishes.map(
                                    (wish,index)=>(
                                        <Grid item xs={12}>
                                            <SmallView key={index}
                                                id={wish.id}
                                                company_view={wish.company_view}
                                                product_view={wish.product_view}
                                                price={wish.price_usd}
                                                quantity={wish.cart_quantity}
                                            />
                                        </Grid>
                                    )
                                )
                            }
                            
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" textAlign="right">Total: ${total}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" sx={{ pr: 5 }} textAlign="left">2 Choose a Shipping Address</Typography>
                        </Grid>
                        <br></br>
                        {/* <form sx={{ width: '100%'}}> */}
                            {addressSelected? 
                                <Grid 
                                container
                                // direction="row-reverse"
                                justifycontent="center"
                                alignitems="center"
                                >
                                    <Grid item xs={2} display="flex" alignItems="center">
                                        <Button 
                                            sx={{ mt: 1, mr: 1 }} 
                                            type="submit" 
                                            variant="contained" 
                                            onClick={()=>{setOpenAddress(true)}}
                                        >
                                        Add
                                        </Button>
                                    </Grid>
                                    <Grid item xs={10} alignItems={"center"}>
                                        <FormControl>
                                            <FormLabel sx={{ width: '100%' }} id="influencer-label">Shipping Address</FormLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={adr}
                                                label="Address"
                                                onChange={handleChangeAddress}
                                                sx={{ 
                                                    width: '100%' 
                                                    }}
                                            >
                                                {
                                                    addresses.map(
                                                        (address,index)=>(
                                                            <MenuItem value={address.id}>{address.address1}</MenuItem>
                                                        )
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    
                                    </Grid>
                                    <Grid item xs={12} md={12} mt='20px' alignItems={"left"}>
                                        {address.name}
                                        <br></br>
                                        {address.address1} {address.address2},
                                        <br></br>
                                        {address.city}, {address.state} {address.zip}
                                    </Grid>
                                </Grid>        
                            :
                                <Grid item xs={6} alignItems={"center"}>
                                    <Button 
                                        sx={{ mt: 1, mr: 1,width: '100%' }} 
                                        type="submit" 
                                        variant="contained" 
                                        onClick={()=>{setOpenAddress(true)}}
                                    >
                                        Add Address
                                    </Button>
                                </Grid>
                            }
                                    
                            
                        {/* </form> */}
                        
                        <Grid item xs={12}>
                            <Button 
                                sx={{ mt: 1, mr: 1,width: '100%' }} 
                                type="submit" 
                                variant="contained" 
                                onClick={handleSubmit}
                                disabled={addressSelected? false : true }
                            >
                                Enter Credit Info
                            </Button>
                        </Grid>
                        <br></br>
                        
                        
                    </Grid>
                    <Dialog
                        open={openAddress}
                        onClose={handleCloseAddress}
                        aria-labelledby="delete-dialog-title"
                        aria-describedby="delete-dialog-description"
                        maxWidth='lg'
                        fullWidth='md'
                    >
                        <DialogTitle id="delete-dialog-title">
                        {"Enter New Billing Information"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="delete-dialog-description">
                                <AddressInput close={()=>{handleCloseAddress()}} addedAddress={(id)=>{newAddress(id)}}/>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseAddress}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openBuy}
                        onClose={handleClose}
                        aria-labelledby="delete-dialog-title"
                        aria-describedby="delete-dialog-description"
                        maxWidth='lg'
                        width='md'
                    >
                        <DialogTitle id="delete-dialog-title">
                        {"Credit Information"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="delete-dialog-description">
                                <Credit close={(order_id)=>{handleOrder(order_id)}} address_id={address.id}/>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            }
            
        </div>
    )
}

const CheckoutPage = () => {
    const isAuth = useAppSelector((state) => state.loginstat.value)


    return (
        <div className="home container">
            {isAuth?
                <LoggedInHome/>
            :
                <Paper>
                    Please Log in to see this page
                </Paper>
            }
        </div>
    )
}


export default CheckoutPage