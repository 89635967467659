import React, { useState } from 'react';

import { Accordion, AccordionSummary, AccordionDetails, Typography, Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';

import { useAppSelector } from '../../app/hooks';
import api from '../api/companies';
import BuyProduct from './BuyProduct';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block"
        },
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        marginRight: '.5em',
    },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
})


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ProductAddon = ({ quantity=1 }) => {
  const showCompany  = useAppSelector((state) => state.viewcompany)
  const [serverResponse,setServerResponse]=useState('Processing...')
  const [showDanger,setShowDanger]=useState(false)
  const [openBuy,setOpenBuy]=useState(false);
  const [products,setProducts]=React.useState([]);
  const viewProduct  = useAppSelector((state) => state.viewproduct)
  const isAuth = useAppSelector((state) => state.loginstat.value)
  const [show,setShow]=useState(true)
  const [checkedIds, setCheckedIds] = useState([]);
  const [bundleMerch, setBundleMerch] = useState([]);
  const [selectedCompanyMerch, setSelectedCompanyMerch] = useState([8]);
  

  React.useEffect(()=> {
    getProductList();
      
  },[]);


  const getProductList = async () => {
        
    try {
        const response = await api.get(`/api/order/product/addon/${viewProduct.id}`).then(data => {
            setProducts(data.data)
            // update wish list to be length of data+1
            const newLength = data.data.length+1;  // Length of data.data
            for (let index = 0; index < data.data.length; index++) {
              const product = products[index];
              const response2 = api.get(`/api/order/init/product/${product.id}/${viewProduct.id}`).then(data2 => {
                console.log(data2.data)
                console.log(index+1)

                })
                .catch(error => console.log(error));
            }
            }).catch(error => console.log(error));
    } catch (err) {
        console.log(err)
    }
  }


  const buyProductMerch = (merch_list,index) => {
    try {
      console.log(merch_list)
      console.log(index)
      console.log(selectedCompanyMerch)
    //   const filteredMerch = selectedCompanyMerch.filter(selectedCompanyMerch => selectedCompanyMerch.product_id !== product_id);
    //   const updated_merch_list = merch_list.forEach(item => {item.wishQuantity = quant});
    //   // setSelectedCompanyMerch([].concat.apply(filteredMerch,merch_list))
    //   const next = filteredMerch.concat(updated_merch_list)
    //   console.log(next)
    //   if (next) {
    //     setSelectedCompanyMerch(next)
    //   }
    const wish = {
        name_view: merch_list.name_view,
        picture_location: merch_list.picture_location,
        describe: merch_list.describe,
        price_usd: merch_list.price_usd,
        quantity: merch_list.quantity,
        manufactured_output: merch_list.manufactured_output,

    }
    } catch (err) {
        console.log(err)
    }
  }



  const handleExpandChange = (panel) => (event, isExpanded) => {
    // dispatch(uploadWish({"checkedProducts": {
    //   ...localWish.checkedProducts,
    //   [panel]: isExpanded,
    // }}))
  };


  const handleCheckboxChange = (panel) => (event) => {
    const isChecked = event.target.checked;
    console.log('test')
    // setChecked((prevChecked) => ({
    //   ...prevChecked,
    //   [panel]: isChecked,
    // }));
    // setExpanded((prevExpanded) => ({
    //   ...prevExpanded,
    //   [panel]: isChecked,
    // }));
  };

  const handleClose = () => {
    setOpenBuy(false);
  };


  return (
    <div style={{ paddingTop: '5px'}}>
      {products.map((product, index) => (
        <Accordion
          key={product.id}
          expanded={product.req}
          // expanded={localWish.checkedProducts[product.id] || false}
          onChange={handleExpandChange(product.id)}
          style={{ border: `solid ${showCompany.secondary_color}`, backgroundColor: '#e0e9e4' }}
        >
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            iconbuttonprops={{
              edge: 'start',
              sx: {
                marginLeft: 0, // Align expand icon to the start
              },
            }}
            aria-controls={`panel${index + 1}bh-content`}
            id={`panel${index + 1}bh-header`}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Checkbox
              {...label}
              
              checked={product.req}
              // checked={localWish.checkedProducts[product.id] || false}
              onChange={handleCheckboxChange(product.id)}
              sx={{
                color: showCompany.primary_color, // Hex value for the checkbox color when unchecked
                '&.Mui-checked': {
                  color: showCompany.primary_color, // Hex value for the checkbox color when checked
                },
                pl: 0
              }}
            />
            {/* {product.picture_location ?
            // {product.picture_location  && !localWish.checkedProducts[product.id]  ?
              <img src={process.env.PUBLIC_URL + '/img' + product.picture_location} alt={product.title} style={{ marginRight: '5px', maxHeight: '50px', minHeight: '40px', alignSelf: 'center' }} />
            :
              <></>
            } */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                        width: '100%', 
                        textAlign: 'left', 
                        alignSelf: 'flex-start' 
                    }}
                  >
                    {product.name_view}
                  </Typography>
                  <Typography 
                    variant="h7" 
                    sx={{ 
                        width: '100%', 
                        textAlign: 'left', 
                        alignSelf: 'flex-start' 
                    }}
                  >
                    {product.title}
                  </Typography>
              </Box>
          </AccordionSummary>
          <AccordionDetails>
            <BuyProduct key={product.id} group_product={viewProduct.id} wishIndex={index+1} buyProductMerch={(merch_list)=>{buyProductMerch(merch_list,index)}} product_id={product.product_id} color={showCompany.secondary_color} quantity={quantity}/>
          </AccordionDetails>
        </Accordion>
      ))}




      <Dialog
        open={openBuy}
        onClose={handleClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
        {"Order Product"}
        </DialogTitle>
        <DialogContent>
        {isAuth?
            <DialogContentText id="delete-dialog-description">
                {show?
                    <Box>
                    <Alert variant="outlined" severity='success' onClose={() => setShow(false)}>
                            {serverResponse}
                        </Alert>
                    </Box>

                    
                    :
                    ""
                }
                {showDanger?
                    <Box>
                        <Alert variant="outlined" severity='warning' onClose={() => setShowDanger(false)} dismissible>
                            {serverResponse}
                        </Alert>
                    </Box>
                    :
                    ''
                }
            </DialogContentText>
        :
            <DialogContentText id="delete-dialog-description">
                You must log in before you can order this product.
            </DialogContentText>
        }
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {isAuth?
            <Link to='/cart' style={{ textDecoration: 'none' }}>
                <Button onClick={handleClose} autoFocus>
                    Checkout
                </Button>
            </Link>
        :
            <Link to='/auth/login' style={{ textDecoration: 'none' }}>
                <Button onClick={handleClose} autoFocus>
                    Log In
                </Button>
            </Link>
        }
        
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductAddon;