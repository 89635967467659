import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import LockRoundedIcon from '@mui/icons-material/LockRounded';


const LogInIcon = ({updateLogin}) => {

  const requestLogin =  async  () => {
    console.log('made it to login React')
    // await UserService.doLogin()
    updateLogin('Login');
    // setIsLoggedIn(UserService.isLoggedIn());
  };


  return (
    <Tooltip title="Account">
        <IconButton id="menu-appbar-md" sx={{ p: 0 }}>
        <Avatar alt="?">
            <LockRoundedIcon onClick={() => {requestLogin('Login')}}/>
        </Avatar>
        </IconButton>
    </Tooltip>
  )
}

export default LogInIcon