import React from 'react'
import Typography from '@mui/material/Typography';
import { Grid, Checkbox } from '@mui/material';
import Box from '@mui/material/Box';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
        selectionImg: {
          color: '#9BCDB0',
          width: '70px',
          marginRight: '.5em',
      },
      })


const MerchandiceCheckbox = (props) => {
    const {merch, checked, label, onMerchClick,viewCheck=true} = props

    const classes=useStyles(props)


    const handleGridClick = () => {
        onMerchClick();
      };

    return (
        <Grid
            item
            xs={12}
            sx={{ pl: '7px', display: 'flex', alignItems: 'stretch', cursor: 'pointer' }}
            onClick={handleGridClick}
        >
            <Box sx={{height: '100%', alignItems: 'sretch', minWidth: '5ch', pr: '5px'}}>
                {/* <FormControlLabel
                sx={{width:'100%'}}
                required control={
                <Checkbox 
                defaultChecked 
                />}
                label="Label" /> */}

                {viewCheck ?
                    <Checkbox
                        {...label}

                        // checked={checked[props.merch.id] || false}
                        checked={checked}
                        onChange={(e) => e.stopPropagation()}
                    />
                    :
                    <></>
                }
                <Typography variant="H5" sx={{ mt: '10px', lineHeight: '.2', width: '100%', flexShrink: 0, textAlign: 'left', alignSelf: 'center' }}>${props.merch.price_usd}</Typography>
            </Box>    
            {props.merch.picture_location ?
                    <img src={process.env.PUBLIC_URL + '/img' + props.merch.picture_location} alt={props.merch.name_view} style={{ marginRight: '5px', maxHeight: '100px', minHeight: '20px', alignSelf: 'center' }} />
                :
                    <></>
            }
            
            <Box sx={{width: '100%'}}>
                <Typography variant="subtitle2" sx={{ mt: '10px', lineHeight: '.4', width: '100%', flexShrink: 0, textAlign: 'left', alignSelf: 'center' }}>{props.merch.name_view}</Typography>
                <Typography variant="body2" sx={{ mt: '10px',width: '100%', flexShrink: 0, textAlign: 'left', alignSelf: 'center' }}>{props.merch.describe}</Typography>
                <Box sx={{width: '100%', display: 'flex'}}>
                <Typography variant="body2" sx={{ mt: '10px',width: '50%', flexShrink: 0, textAlign: 'left', alignSelf: 'center' }}>Stock Quantity: {props.merch.quantity}</Typography>
                </Box>
            </Box>
        </Grid>
    );
}

export default MerchandiceCheckbox

