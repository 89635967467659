import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import api from '../api/companies';


export default function UserList() {
    const [rows, setRows] = React.useState([]);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [userId, setId] = React.useState(0);
    const [inputText, setInputText] = useState('');

    const handleClickOpen = () => {
        setOpenEdit(true);
      };
    
    const handleClose = () => {
        setOpenEdit(false);
      };

    useEffect(()=> {
        const getAdminAllUsers = async () => {
        
            try {
                const response = await api.get(`/api/stakeholder/admin/users`);
                    console.log(`data: ${JSON.stringify(response.data,null,2)}`)
                    // setRows(JSON.stringify(response.data,null,2))
                    setRows(response.data)
            } catch (err) {
                console.log(err)
                if (err.response) {
                    console.log(err.response.data)
                    console.log(err.response.status)
                } else {
                    console.log(`error: ${err.message}`)
       
                }
            }
        }
        getAdminAllUsers();
    },[]);

  return (
    <Paper elevation={5} className="App-paper-full">
        <TableContainer>
        <Table sx={{ minWidth: 650, maxWidth: 800 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Views</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Photo</TableCell>
                <TableCell>Created</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row) => (
                <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row">
                    {row.id}
                </TableCell>
                <TableCell align="right">{row.username}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.views}</TableCell>
                <TableCell align="right">{row.featured_company}</TableCell>
                <TableCell align="right">{row.picture_location}</TableCell>
                <TableCell align="right">{row.created}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    </Paper>
  );
}
