// import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../components/api/companies';
import { histcompanySet } from './histcompany';

export const fetchCompanyData = createAsyncThunk(
  'viewcompany/fetchCompanyData',
  async (companyId, { getState, dispatch }) => {
    if (companyId == null) {
      // localStorage.setItem('Company',initialState)
      return initialState;
    } else if (companyId == 0) {
      // localStorage.setItem('Company',initialState)
      return initialState;
    } else {
      const response = await api.get(`/api/company/id/${companyId}`);
      const data = response.data;
      // localStorage.setItem('Company',data)
      // return response.data;

      // Save the fetched data to localStorage
      const currentState = getState().viewcompany;
      const newState = { ...currentState, ...data };
      dispatch(histcompanySet(newState))
      
      return data;
    }
  }
);


const initialState = {
  "id": 0,
  "marker": "",
  "logo": "/brackettinnovations/logo_dark.png",
  "template_id": 0,
  "name_code": "brackettinnovations",
  "name_view": "",
  "primary_color": "#366C4D",
  "secondary_color": "#006400",
  "loading": false,
  "error": null,
};

const viewcompanySlice = createSlice({
  name: 'viewcompany',
  initialState,
  reducers: {
    viewcompanyCompany(state, action) {
      // it's okay to do this because immer makes it immutable
      // under the hoo
      return action.payload;
    },
    viewcompanySet(state, comp) {
      // it's okay to do this because immer makes it immutable
      // under the hoo
      return initialState;
    },
    viewInfluencer(state, action) {
      // it's okay to do this because immer makes it immutable
      // under the hoo
      console.log(action.payload)
      console.log(action.payload.username)
      return action.payload;
    }
    // decrement
    // reset
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanyData.fulfilled, (state, action) => {
        state.loading = false;
        Object.assign(state, action.payload);
      })
      .addCase(fetchCompanyData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { viewcompanyCompany, viewInfluencer, viewcompanySet } = viewcompanySlice.actions;
export default viewcompanySlice.reducer;