import React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Container';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom'
import InfluencerIcon from './InfluencerIcon';
import LogInIcon from './LogInIcon';
import Box from '@mui/material/Box';
import DisplayName from './DisplayName';
import StakeholderIcon from './StakeholderIcon';
import Typography from '@mui/material/Typography';
import CartIcon from './CartIcon';
import GlowButton from './GlowButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { loginLoad } from '../../features/loading';
import { stakeholderLogin, stakeholderLogout } from '../../features/login';
import { fetchCompanyData } from '../../features/viewcompany';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import jwtCheck from '../../services/jwtCheck';


const useStyles = makeStyles({
  navlogolg: {
    color: '#9BCDB0',
    maxWidth: '230px',
    marginRight: '.5em',
    marginTop: '4%',
    maxHeight: '250px',
  },
  navlogomd: {
    color: '#9BCDB0',
    width: '70px',
    marginRight: '.5em',
},
})

const Template1Component = ({ templateId }) => {
  const classes = useStyles()
  const showCompany  = useAppSelector((state) => state.viewcompany)
  const isAuth = useAppSelector((state) => state.loginstat.value)
  const isLoading = useAppSelector((state) => state.loader.value)
  const [personalize, setPersonalize] = React.useState({
    "cart_number": 0,
    "influencer_number": 1
  });
  const follow = useAppSelector((state) => state.followinfluencer.picture_location)
  const dispatch = useAppDispatch()

  const updateLogin =  async  (action) => {
    // this sets isLoading to the delay does not confonfuse . intended for a loading icon
    dispatch(loginLoad())
    console.log(action + " Pressed, updating login")

  if (action==="Logout") {
    // logs out r if they are logged in
    if (jwtCheck.isLoggedIn()) {
      await jwtCheck.doLogout()
    }
    // changes global logout state
    dispatch(stakeholderLogout())
    // deleteRoles()
  } else if (action=='Login') {
    // this will log in user
    if (!jwtCheck.isLoggedIn()) {
      await jwtCheck.doLogin()
    }
    console.log('-----------wanted to login GUI---------------')
    // console.log('Wanted to be logedin')
    //This sets global is auth value
    dispatch(stakeholderLogin())
    // logout({ redirectUri: 'http://localhost:3000/' })
  } else {
    console.log('Weird action {action}')
  }
};

const handleReload = () => {
  window.location.reload();
}

  const selected = (company_id) => {
    dispatch(fetchCompanyData(company_id))
  }
  
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
      <Toolbar disableGutters>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
          <img src={process.env.PUBLIC_URL + '/img' + showCompany.logo} className={classes.navlogomd} alt='Image'/> 
        </Box>
        <DisplayName name={showCompany.name_view}/>    
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'} }}>
              <GlowButton code={`/company/${showCompany.name_code}`} name="PRODUCTS" mr='15px'  primary_color={showCompany.primary_color} secondary_color={showCompany.secondary_color}/>
              <GlowButton code="/" name="HOME" mr='15px'  primary_color={showCompany.primary_color} secondary_color={showCompany.secondary_color}/>
        </Box>
        {isAuth ?
          <Box sx={{display: { xs: 'none', md: 'flex' }}}>
            <InfluencerIcon
              photo={follow}
              influencer_number={personalize.influencer_number}
            />
            <CartIcon cart_number={personalize.cart_number} />
            <StakeholderIcon
              loading="true"
              profile_picture={follow}
              updateLogin={(action) => updateLogin(action)}
            />
          </Box>
        :
          <Box sx={{display: { xs: 'none', md: 'flex' }}}>
            <InfluencerIcon
              photo={follow}
              influencer_number={personalize.influencer_number}
            />
            {isLoading ? 
              <Box alignSelf='center' sx={{ width: '40px' }}>
                <Box onClick={() => handleReload()} style={{ textDecoration: 'none' }}>
                  ...
                </Box>
              </Box>
            :
              // action is a string of "login"
              <LogInIcon updateLogin={(action) => {updateLogin(action)}}/>
            }
            
          </Box>
          }

        <Grid 
            container
            spaceing={0}
            sx={{
            display: { xs: 'flex', md: 'none' }, 
            maxWidth:'100vw',
            padding: 0,
            margin: 0,
            textAlign: 'center',
            }}
            justifycontent="center"
            alignItems="flex-start"
        >
          <Grid item xs={4} sx={{padding: 0, margin: 0, width: '100%', display: { xs: 'block', md: 'none' },jusrtifyContent: 'center',alignItems: 'center'}}>
            <Grid 
              container
              spaceing={0}
              sx={{
                display: { xs: 'flex', md: 'none' }
              }}
              >
                <Grid item xs={isAuth && !isLoading ? 6: 12}>
                    {isLoading ?
                      <Box onClick={() => handleReload()} style={{ textDecoration: 'none' }}>
                        ...
                      </Box>
                    :
                      <Tooltip title="Account">
                        {isAuth?
                            <StakeholderIcon
                              profile_picture={follow}
                              updateLogin={(action) => updateLogin(action)}
                            />
                          :
                          <LogInIcon updateLogin={(action) => {updateLogin(action)}}/>
                        }
                      </Tooltip> 
                    }
                
                  </Grid>
                  <Grid item xs={isAuth? 6: 0} marginTop={isAuth? '6%': '0%'}>
                      {isAuth && !isLoading ?
                        <CartIcon cart_number={personalize.cart_number} />
                      :
                        <div>                    
                        </div>
                      }

                  </Grid>
              </Grid>
              <Grid item xs={12} marginTop='5%' sx={{justifycontent:"center"}}>
                <GlowButton code={`/company/${showCompany.name_code}`} name="PRODUCTS"  primary_color={showCompany.primary_color} secondary_color={showCompany.secondary_color}/>
                <GlowButton code="/" name="HOME"  primary_color={showCompany.primary_color} secondary_color={showCompany.secondary_color}/>
              </Grid>
              <Grid container spacing={0} sx={{ display: { xs: 'flex', md: 'none' } }}>
                <Grid item marginTop='5%'>
                  {/* <Grid item xs={showBadges ? 6: 0} marginTop='5%'> */}
                  {/* {showBadges ?
                      <Tooltip title="Badges">
                          <IconButton color="inherit">
                              <Avatar 
                                alt="*"
                                src={process.e988888888888nv.PUBLIC_URL + '/img' + badgeImage}
                                onClick={badgeSwitch}
                                size="large"
                              >
                              </Avatar>
                          </IconButton>
                      </Tooltip>
                    :
                      <div></div>
                  } */}
                </Grid>
                <Grid item xs={12} marginTop='5%'>
                {/* <Grid item xs={showBadges ? 6: 12} marginTop='5%'> */}
                  <InfluencerIcon
                    photo={follow.picture_location}
                    influencer_number={personalize.influencer_number}
                  />
                </Grid>
              </Grid>
          </Grid>
          
          <Grid item xs={8} sx={{ padding: 0, margin: 0, width: '100%'}}>
            <Grid 
              container
              alignItems="center"
              justifyContent="center"
              sx={{
              display: { xs: 'inline-block', md: 'none' }, 
              flexGrow: 1,
              position: 'relative',
              textAlign: 'center',
              }}
              // direction="row-reverse"
              justifycontent="center"
              alignitems="center"
            >
              <Grid item xs={12} sx={{padding: 0, margin: 0}}>
                <img src={process.env.PUBLIC_URL + '/img' + showCompany.logo} className={classes.navlogolg} alt="Logo" />
              </Grid>
              <Grid item xs={12} sx={{
                display: { xs: 'block', md: 'none' },
              }}>
                <Typography
                  variant="h4"
                  style={{ wordWrap: "break-word" }}
                  align="center"
                  href="/"
                  sx={{
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.1.5rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    lineHeight: '1.2',
                    textAlign: 'center',
                    padding: 0, margin: 0, maxWidth: '100%'
                  }}
                >
                  {showCompany.name_view}
                </Typography>
              </Grid>
            </Grid> 
          </Grid>
        </Grid>
      </Toolbar>
    </Container> 
  </AppBar>
  );
};

export default Template1Component;