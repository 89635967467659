import React from 'react';
import Card from './Card'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const promise = loadStripe("pk_live_51NCU9UJ85f607jaOdfsHtjiVTUPNm7nrunbj0CJQWGublbTviqpWBgUMj1w6Pw576XAKbdEXRQG79SS28omIfwBN00HnF1m16w");
// const promise = loadStripe('pk_test_51NCU9UJ85f607jaOEC5h8GyNXlAngnkrTBmi67sEeoAnzydp3N8FFgTyj5cbFLQTS0yZcXaVkQajjC7pjFal8vWQ00KYHzgvGx')

const Credit = ({close, address_id}) => {
  
  const orderPending = (id) => {
    close(id)
  }

    return (
      <div>
        <Elements stripe={promise}>
          <Card close={(id)=>{orderPending(id)}} address_id={address_id}/>
        </Elements>
      </div>
  );
}

export default Credit