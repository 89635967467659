// import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../components/api/companies';




export const fetchHistCompanyData = createAsyncThunk(
  'viewcompany/fetchCompanyData',
  async (companyId, { getState }) => {
    console.log(initialState)
    console.log(companyId)
    if (companyId == null) {
      // localStorage.setItem('Company',initialState)
      return initialState;
    } else if (companyId == 0) {
      // localStorage.setItem('Company',initialState)
      return initialState;
    } else {
      const response = await api.get(`/api/company/id/${companyId}`);
      const data = response.data;
      // localStorage.setItem('Company',data)
      // return response.data;

      // Save the fetched data to localStorage
      const currentState = getState().viewcompany;
      const newState = { ...currentState, ...data };
      localStorage.setItem('currentState', JSON.stringify(currentState));
      // localStorage.setItem('Company', JSON.stringify(newState));
      
      return data;
    }
  }
);


const initialState = {
  "id": 1,
  "marker": "bin",
  "logo": "/brackettinnovations/logo_dark.png",
  "template_id": 1,
  "name_code": "brackettinnovations",
  "name_view": "Brackett Innovations",
  "primary_color": '#366C4D',
  "secondary_color": '#006400',
  "loading": false,
  "error": null,
};

const histcompanySlice = createSlice({
  name: 'histcompany',
  initialState,
  reducers: {
    histcompanyCompany(state, action) {
      // it's okay to do this because immer makes it immutable
      // under the hoo
      return action.payload;
    },
    histcompanySet(state, comp) {
        if (comp.payload.id != 0) {
          return comp.payload
        }
      return initialState;
    }
    // decrement
    // reset
  }
});

export const { histcompanySet } = histcompanySlice.actions;
export default histcompanySlice.reducer;