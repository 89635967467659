import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox'
import { useEffect, useState } from 'react'
import apit from '../api/authApi';

import MediaInput from './MediaInput'
import WishMerchandice from './WishMerchandice'

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
            },
        display: "block"
        },
    productimage: {
        color: '#9BCDB0',
        width: '100%',
        maxWidth: '200px',
        marginRight: '.5em',
    },
    navlogosm: {
        color: '#9BCDB0',
        width: '70px',
        marginRight: '.5em',
    },
})

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Wish = ({key,order,getCarts}) => {
    const [ showAddon, setShowAddon ] = useState(false);
    const [ addons, setAddons ] = useState([]);
    const [showDelete,setShowDelete]=useState(false)
    const [showMedia,setShowMedia]=useState(false)
    const [showMediaNeeded,setShowMediaNeeded]=useState(false)
    const [editAddonId,setEditAddonId]=useState(0)
    const [showDanger,setShowDanger]=useState(false)
    const [serverResponse,setServerResponse]=useState('Processing...')
    const [message,setMessage]=useState("Wlellcome");
    const classes = useStyles()

    const changeClicked = () =>{
        if ((order.addon_media==1 && order.complete) || order.checked) {
            apit.get(`/api/order/cart/${order.id}/clicked`).then(data => {
                getCarts()
            })
        } else if (order.checked) {
            setShowMediaNeeded(true)
        }
    }
    const addonClicked = () =>{
        console.log(`clicked id ${String(order.id)}`)
        // apit.get(`/api/order/${order.id}/cart/checked`).then(data => {
        //     getCarts()
        // })
        
        if (!showAddon) {
            apit.get(`/api/order/cart/${order.id}/addons`).then(data => {
                setAddons(data.data)
            })
        }
        setShowAddon(!showAddon)
    }

    const handleClose = () => {
        setShowDelete(false);
      };
    
      const handleCloseMedia = () => {
        setShowMedia(false);
        getCarts()
      };
      const handleCloseMediaNeeded = () => {
        setShowMediaNeeded(false);
      };
      const GetCart = () => {
        getCarts()
      };
    
    const deleteWish = () =>{
        console.log(order.id)
        apit.delete(`/api/order/cart/${order.id}/update`).then(data => {
            getCarts()
        })
        setShowDelete(false)
    }
    return (
        <Grid 
            container
            // direction="row-reverse"
            justify="center"
            alignitems="center"
            sx={{border: `solid ${order.primary_color}`, borderWidth: .7, borderRadius: 2}}
        >
            <Grid item xs={12} md={10} sx={{ paddingRight: '.5em' }} display="flex" alignItems={"center"}>
                {/* <Box>
                    <Checkbox {...label} onClick={changeClicked} defaultChecked={checked? true: false}/>
                </Box> */}

                    <WishMerchandice 
                        id={order.id}
                        name={order.name}
                        picture={order.picture_location}
                        total={order.price_usd}
                        description={order.describe}
                        quantity={order.cart_quantity}
                        influencer={order.username}
                        checked={order.checked}
                        company_code={order.company_view}
                        title={order.name_view}
                        company={order.company_name}
                        primary_color={order.primary_color}
                        changeClick={(value) => {changeClicked()}}
                    />
            </Grid>
            <Grid item xs={12} md={2} alignItems={"center"}>
                <Grid 
                        container
                        // direction="row-reverse"
                        justify="center"
                        alignitems="center"
                >
                    <Grid item xs={6} md={12} sx={{display: { xs: 'none', md: 'flex' }}} alignItems={"center"}>
                        <Button
                            key={key}
                            variant="outlined"
                            onClick={()=>{setShowDelete(true)}}
                            // onClick={deleteWish}
                            sx={{ 
                            my: .5, 
                            display: 'block',
                            marginRight: 'auto',
                            width: '90%', 
                            marginLeft: 'auto',    
                            }}
                        >
                            Delete
                        </Button>
                    </Grid>
                    {/* <Grid item xs={6} md={12} sx={{display: 'none'}} alignItems={"center"}>
                        <Link to={'/company/' + order.company_view + '/order/' + order.id} style={{ textDecoration: 'none' }}>
                            <Button
                                key={key}
                                variant="outlined"
                                sx={{ 
                                my: .5, 
                                display: 'block',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                width: '90%',
                                }}
                            >
                                Edit
                            </Button>
                        </Link>
                    </Grid> */}
                    <Grid item xs={6} md={12} sx={{display: 'block'}} alignItems={"center"}>
                        <Button
                            key={key}
                            variant={order.complete? "outlined":"contained"}
                            onClick={()=>{setEditAddonId(0);setShowMedia(true)}}
                            sx={{ 
                            my: .5, 
                            display: 'block',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            width: '90%',
                            }}
                            color={order.complete? "success":"error"}
                        >
                                    {order.complete ?
                                        <>Media</>
                                        :
                                        <>
                                            Add Media
                                        </>
                                    }
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={12} sx={{display: { xs: 'block', md: 'none' }}} alignItems={"center"}>
                        <Button
                            key={key}
                            variant="outlined"
                            onClick={()=>{setShowDelete(true)}}
                            sx={{ 
                            my: .5, 
                            display: 'block',
                            marginRight: 'auto',
                            width: '90%', 
                            marginLeft: 'auto',    
                            }}
                        >
                            Delete
                        </Button>
                    </Grid>  
                    {order.addons==0?
                        <></>
                    :
                        <Grid item xs={6} md={12} alignItems={"center"}>
                            <Button
                                key={key}
                                variant={order.addon_media? "outlined":"contained"}
                                // onClick={deleteWish}
                                sx={{ 
                                my: .5, 
                                display: 'block',
                                marginRight: 'auto',
                                width: '90%', 
                                marginLeft: 'auto',    
                                }}
                                color={order.addon_media? "success":"error"}
                                onClick={()=>(addonClicked())}

                            >
                                Addon
                            </Button>
                        </Grid>
                    }            
                </Grid>
            </Grid>
            {showAddon?
                    <Grid 
                    container
                    // direction="row-reverse"
                    justify="center"
                    alignitems="center"
                    sx={{border: `solid ${order.primary_color}`, borderWidth: .7, borderRadius: 2}}
                >
                    
                    {
                        addons.map(
                            (add_merch,index)=>(
                                <Grid 
                                container
                                // direction="row-reverse"
                                justify="center"
                                alignitems="center"
                                sx={{border: `solid ${order.primary_color}`,backgroundColor: `${order.primary_color}`, borderWidth: .7, borderRadius: 2}}
                            >
                            <Grid item xs={12} md={10} sx={{ paddingRight: '.5em' }} display="flex" alignItems={"center"}>
                                <Grid item xs={12}>
                                    <WishMerchandice 
                                        id={add_merch.id}
                                        picture={add_merch.picture_location}
                                        description={add_merch.describe}
                                        quantity={add_merch.cart_quantity}
                                        checked={order.checked}
                                        title={add_merch.name_view}
                                        total={add_merch.price_usd}
                                        primary_color={add_merch.primary_color}
                                        changeClick={()=>(console.log('nothing'))}
                                    />
                                </Grid>
                            </Grid>
                             <Grid item xs={12} md={2} sx={{display: 'block'}} alignItems={"center"}>
                                {add_merch.media_id!=1?
                                <Button
                                    key={key}
                                    variant={add_merch.complete || order.addon_media? "outlined":"contained"}
                                    onClick={()=>{setEditAddonId(add_merch.id);setShowMedia(true)}}
                                    sx={{ 
                                    my: .5, 
                                    display: 'block',
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                    width: '90%',
                                    }}
                                    color={add_merch.complete || order.addon_media? "success":"error"}
                                >
                                        {add_merch.complete ?
                                            <>Media</>
                                            :
                                            <>
                                                Add Media
                                            </>
                                        }
                                
                                    </Button>
                                :<></>}    
                            </Grid>
                                </Grid>
                                )
                        )
                    }
                    
                   
                </Grid>
            :
                <></>
            }

            <br></br>
            <Dialog
                open={showDelete}
                onClose={handleClose}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">
                {"Delete Wish"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Do you want to delete this wish?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Link to='/cart' style={{ textDecoration: 'none' }}>
                    <Button onClick={deleteWish} autoFocus>
                        Delete
                    </Button>
                </Link>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showMediaNeeded}
                onClose={handleCloseMediaNeeded}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
                fullWidth
                maxWidth={'xl'}
            >
                <DialogTitle id="delete-dialog-title">
                {"Media Required"}
                </DialogTitle>
                <DialogContent>
                    "There is Media required for this order, please select the red Media or Addon/Media button to add required Media select for to checkout"
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseMediaNeeded}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showMedia}
                onClose={handleCloseMedia}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
                fullWidth
                maxWidth={'xl'}
            >
                <DialogTitle id="delete-dialog-title">
                {"Update Media"}
                </DialogTitle>
                <DialogContent>
                    <MediaInput cartaddon_id={editAddonId} cart_id={order.id} color={order.primary_color} updateCarts={()=>{GetCart()}}/>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseMedia}>Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>

    )
}


export default Wish