import React, {useEffect, useState} from'react';
import ReactConfetti from 'react-confetti';

const Confetti = ({open}) => {
    const [windowDimension, setWindowDimension] = useState({width: window.innerWidth, height: window.innerHeight})
    const [showConfetti, setShowConfetti] = useState(open);
    const detectSize = () =>{
        setWindowDimension({width: window.innerWidth, height: window.innerHeight});
    }

    useEffect(()=>{
        setShowConfetti(open)
    },[open]);

    useEffect(()=>{
        window.addEventListener('resize',detectSize);
        return()=>{
            window.removeEventListener('resize',detectSize);
        }
    },[windowDimension]);
    return (
        <div>
            {showConfetti && <ReactConfetti>
                width={windowDimension.width}
                height={windowDimension.height}
                tweenDuration={10}
                colors={["#9BCDB0"]}  
            </ReactConfetti>}
        </div>
    )
}

export default Confetti;