import React from 'react';
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Container';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import LogInIcon from './LogInIcon';
import InfluencerIcon from './InfluencerIcon';
import CartIcon from './CartIcon';
import StakeholderIcon from './StakeholderIcon';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';

import DisplayName from './DisplayName';
import GlowButton from './GlowButton';
import { fetchCompanyData } from '../../features/viewcompany';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import jwtCheck from '../../services/jwtCheck';
import { loginLoad } from '../../features/loading';
import { stakeholderLogin, stakeholderLogout } from '../../features/login';


const useStyles = makeStyles({
  navlogolg: {
    color: '#9BCDB0',
    width: '100%',
    marginRight: '.5em',
},
  navlogomd: {
    color: '#9BCDB0',
    width: '70px',
    marginRight: '.5em',
},
})

const Template0Component = ({ templateId }) => {
  const classes = useStyles()
  const [personalize, setPersonalize] = React.useState({
    "cart_number": 0,
    "influencer_number": 1
  });
  const lastCompany  = useAppSelector((state) => state.histcompany)
  const showCompany  = useAppSelector((state) => state.viewcompany)
  const isAuth = useAppSelector((state) => state.loginstat.value)
  const isLoading = useAppSelector((state) => state.loader.value)
  const follow = useAppSelector((state) => state.followinfluencer.picture_location)
  const [influencerPhoto, setInfluencerPhoto] = React.useState('/default.jpg');
  const dispatch = useAppDispatch()

  const updateLogin =  async  (action) => {
    // this sets isLoading to the delay does not confonfuse . intended for a loading icon
    dispatch(loginLoad())
    console.log(action + " Pressed, updating login")

    if (action==="Logout") {
      // logs out r if they are logged in
      if (jwtCheck.isLoggedIn()) {
        await jwtCheck.doLogout()
      }
      // changes global logout state
      dispatch(stakeholderLogout())
      // deleteRoles()
    } else if (action=='Login') {
      // this will log in user
      if (!jwtCheck.isLoggedIn()) {
        await jwtCheck.doLogin()
      }
      console.log('-----------wanted to login GUI---------------')
      // console.log('Wanted to be logedin')
      //This sets global is auth value
      dispatch(stakeholderLogin())
      // logout({ redirectUri: 'http://localhost:3000/' })
    } else {
      console.log('Weird action {action}')
    }
  };

  const handleReload = () => {
    window.location.reload();
  }

  const selected = (company_id) => {
    dispatch(fetchCompanyData(company_id))
  }
  
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
      <Toolbar disableGutters>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
          <img src={process.env.PUBLIC_URL + '/img' + showCompany.logo} className={classes.navlogomd} alt='Image'/> 
        </Box>
        <DisplayName name={showCompany.name_view} />    
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <GlowButton code="/" name="ALL PRODUCTS" mr='15px'  primary_color={showCompany.primary_color} secondary_color={showCompany.secondary_color}/>
              <GlowButton code="/influencers" name="SERVICES" mr='15px'  primary_color={showCompany.primary_color} secondary_color={showCompany.secondary_color}/>
              <Link
                  onClick={()=>{selected(lastCompany.id);}}
                  style={{ textDecoration: 'none' }}
                  // to={`/company/${lastCompany.name_code}`}
                >
                    <GlowButton 
                      name={lastCompany.name_view}
                      code={`/company/${lastCompany.name_code}`}
                      primary_color={lastCompany.primary_color}
                      secondary_color={lastCompany.secondary_color}
                  />
                </Link>
        </Box>
        {isAuth ?
            <Box sx={{display: { xs: 'none', md: 'flex' }}}>
              <InfluencerIcon
                photo={follow}
                influencer_number={personalize.influencer_number}
              />
              <CartIcon cart_number={personalize.cart_number} />
              <StakeholderIcon
                loading="true"
                profile_picture={follow}
                updateLogin={(action) => updateLogin(action)}
              />
            </Box>
          :
          <Box sx={{display: { xs: 'none', md: 'flex' }}}>
            <InfluencerIcon
              photo={follow}
              influencer_number={personalize.influencer_number}
            />
            {isLoading ? 
              <Box alignSelf='center' sx={{ width: '40px' }}>
                <Box onClick={() => handleReload()} style={{ textDecoration: 'none' }}>
                  ...
                </Box>
              </Box>
            :
              // action is a string of "login"
              <LogInIcon updateLogin={(action) => {updateLogin(action)}}/>
            }
            
          </Box>
          }
        <Box 
          sx={{
            display: { xs: 'flex', md: 'none' }, mr: 1, flexGrow: 1, position: 'relative', textAlign: 'center',
          }}
        >
          <Box>
            <img src={process.env.PUBLIC_URL + '/img' + showCompany.logo} className={classes.navlogolg} alt="[i]" />
          </Box>
          <Box 
            sx={{ display: { xs: 'block', md: 'none' },
            position: 'absolute',
            marginTop: '9%',
            top: 0,
            left: 1,
            right: 0,
            bottom: 0,
            justifyContent: "center",
            alignItems: "center",
          }}>
            {isLoading ?
              <Box onClick={() => handleReload()} style={{ textDecoration: 'none' }}>
                ...
              </Box>
            :
              <Tooltip title="Account">
                {isAuth && !isLoading?
                    <StakeholderIcon
                      profile_picture={follow}
                      updateLogin={(action) => updateLogin(action)}
                    />
                  :
                    <LogInIcon updateLogin={(action) => {updateLogin(action)}}/>
                }
              </Tooltip> 
            }
            
          </Box>
          <Box 
            sx={{ display: { xs: 'block', md: 'none' }, 
            position: 'absolute',
            marginTop: '25%',
            top: 0,
            left: 1,
            right: 0,
            bottom: 0,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}>
            <Grid 
              container spaceing={2}
              sx={{
              display: { xs: 'block', md: 'none' }, 
              mr: 1,
              flexGrow: 1,
              position: 'relative',
              textAlign: 'center',
              }}
              // // direction="row-reverse"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item sx={12}>
                <GlowButton code="/" name="ALL PRODUCTS"  primary_color={showCompany.primary_color} secondary_color={showCompany.secondary_color}/>
                <GlowButton code="/influencers" name="SERVICES"  primary_color={showCompany.primary_color} secondary_color={showCompany.secondary_color}/>
                <Link
                    onClick={()=>{selected(lastCompany.id);}}
                    style={{ textDecoration: 'none' }}
                  >
                    <GlowButton 
                      name={lastCompany.name_view}
                      code={`/company/${lastCompany.name_code}`}
                      primary_color={lastCompany.primary_color}
                      secondary_color={lastCompany.secondary_color}
                  />
                </Link>
                
              </Grid>
              <Grid 
                container spaceing={2}
                sx={{
                display: { xs: 'flex', md: 'none' }, 
                mr: 1,
                flexGrow: 1,
                position: 'relative',
                textAlign: 'center',
                }}
                // // direction="row-reverse"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={3} marginTop='1em'></Grid>

                <Grid item xs={2} marginTop='1em'>
                </Grid>
                <Grid item xs={2} marginTop='1em'>
                  <InfluencerIcon
                    photo={influencerPhoto}
                    influencer_number={personalize.influencer_number}
                  />
                </Grid> 

                <Grid item xs={2} marginTop='1em'> 
                  {isAuth && !isLoading ? 
                      <CartIcon cart_number={personalize.cart_number} />
                    :
                      <div></div>
                    }  
                  </Grid>
                  
                  <Grid item xs={3} marginTop='1em'></Grid>
                </Grid>
            </Grid>
          </Box>
        </Box>
      </Toolbar>
    </Container> 
  </AppBar>
  );
};

export default Template0Component;