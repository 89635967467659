// import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../components/api/companies';

export const fetchProductData = createAsyncThunk(
  'viewproduct/fetchProductData',
  async (productId, { getState }) => {
    const response = await api.get(`/api/company/id/${productId}`);
    const data = response.data;
    // localStorage.setItem('Product',data)
    // return response.data;

    // Save the fetched data to localStorage
    const currentState = getState().viewproduct;
    console.log(currentState)
    const newState = { ...currentState, ...data };
    localStorage.setItem('HistState', JSON.stringify(newState));
    // localStorage.setItem('Product', JSON.stringify(newState));
    
    return data;
  }
);


const initialState = {
  "picture_location": '/default.jpg',
  "id":2,
  "name_view":"Wood tag",
  "describe":"A wooden tag to personalize the product to their own online gift message"
};

const viewproductSlice = createSlice({
  name: 'viewproduct',
  initialState,
  reducers: {
    viewProductProduct(state, action) {
      // it's okay to do this because immer makes it immutable
      // under the hoo
      return action.payload;
    },
    viewproductSet(state, prod) {
      return prod.payload;
    }
    // decrement
    // reset
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductData.fulfilled, (state, action) => {
        state.loading = false;
        Object.assign(state, action.payload);
      })
      .addCase(fetchProductData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { viewproductProduct, viewproductSet } = viewproductSlice.actions;
export default viewproductSlice.reducer;