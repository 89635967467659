import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
};

const initialLoginState = {
  value: 0,
};

const loginstatSlice = createSlice({
  name: 'loginstat',
  initialState,
  reducers: {
    stakeholderLogin(state) {
      // it's okay to do this because immer makes it immutable
      // under the hoo
      state.value = 1;
    },
    stakeholderLogout(state) {
      state.value = 0;
    },
    // decrement
    // reset
  },
});

export const { stakeholderLogin, stakeholderLogout } = loginstatSlice.actions;
export default loginstatSlice.reducer;

