import { useEffect, useState } from 'react'
import ListProducts from './ListProducts'
import { Grid } from '@mui/material';
import api from '../api/companies';
import { viewcompanySet } from '../../features/viewcompany';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

const ListProductPage = ({logged=true}) => {
    const [products,setProducts]=useState([]);
    const showCompany  = useAppSelector((state) => state.viewcompany)
    const dispatch = useAppDispatch()

    useEffect(()=> {
        const getCompanyList = async () => {
            if (!showCompany.id) return ;
            try {
                const response = await api.get(`/api/company/product/company/${showCompany.id}`).then(data => {
                    setProducts(data.data)
                }).catch(error => console.log(error));
            } catch (err) { 
                console.log(err)
            }
        }
        getCompanyList();
    },[showCompany.id]);

    return (
        <div className="home container">
            {/* // <Nav search={search} setSearch={setSearch} /> */}
            <Grid container justifyContent="center" alignItems="center">
                <ListProducts products={products}/>
            </Grid>
        </div>
    )
}


export default ListProductPage