import { createSlice } from '@reduxjs/toolkit';

const initialState = {
};

const cartlistSlice = createSlice({
  name: 'cartlist',
  initialState,
  reducers: {
    uploadCartItem(state, action) {
      // it's okay to do this because immer makes it immutable
      // under the hoo
      // console.log('made it')
      return action.payload;
    },
    // decrement
    // reset
  },
});

export const { uploadCartItem } = cartlistSlice.actions;
export default cartlistSlice.reducer;