
import React,{useState} from 'react'
import PasswordIcon from '@mui/icons-material/Password';
import { Paper, Grid, Container } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import {useForm} from 'react-hook-form'
import apit from '../api/authApi';
// import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    navlogosm: {
        color: '#9BCDB0',
        width: '100px',
        marginRight: '.5em',
    },

})

const ImageEditor = ({ key, value, id, cart_id, cartaddon_id, updateCarts}) => {
    const classes = useStyles()
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();
    // const { register, handleSubmit, reset } = useForm();
    const [showDanger,setShowDanger]=useState(false)
    const [serverResponse,setServerResponse]=useState('')
    const [openImage, setOpenImage] = useState(false)
    const [picture, setPicture] = useState(null)

    const handleAddPictureClose = () => {
        setOpenImage(false);
    };

    const handleFileChange=e=>{
        // setPicture(e.target.files[0])
        setPicture(e.target.files[0]);
    }


    const EditMediaslicePicture = (data) => {
        //     // const storageRef="/home/jbrackett/invest/investment/client/public/"
    
            const body = new FormData()
            body.append("picture_name", picture)
            body.append("mediaslice_id", id)
    
            // apit.post('/api/auth/profile/picture',body).then(data => {
            //     console.log(data.data)
            //     // dispatch(uploadCartItem({}));
            //     // dispatch(fetchCompanyData(0))
            //     // window.location.href = 'http://localhost:3000/cart';
            // })
            apit.put('/api/order/mediaslice/picture', body, {
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    'type':'formData',
                }
            }).then(data => {
                console.log(data.data);
                updateCarts()
                setOpenImage(false);
            }).catch(error => {
                console.error('Error:', error);
            });
    
        }


    return (
        <Grid 
        container
        // direction="row-reverse"
        justify="center"
        alignitems="center"
        >
            <Grid item xs={9} display="flex" alignItems="center" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/img' + value} className={classes.navlogosm} alt='Image'/>

            </Grid>
                
            
            <br></br>
            <Grid item xs={3} display="flex" alignItems="center">
                <Button
                    onClick={()=>{setOpenImage(true)}}
                    // onClick={deleteWish}
                    variant={"outlined"}
                    sx={{ 
                    my: .5, 
                    display: 'block',
                    marginRight: 'auto',
                    width: '90%', 
                    marginLeft: 'auto',    
                    }}
                >
                    Edit
                </Button>
            </Grid>
            <Dialog
                    maxWidth='xs'
                    fullWidth
                    open={openImage} 
                    onClose={handleAddPictureClose}
                    size="large"
                >
                    <DialogTitle>Update Profile Picture</DialogTitle>
                    <DialogContent>
                     <TextField
                        autoFocus
                        id="picture"
                        type="file"
                        accept=".png,.jpg"
                        fullWidth
                        variant="outlined"
                        onChange={handleFileChange}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button 
                        onClick={handleAddPictureClose}
                        className={classes.btn}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.btn}
                        onClick={EditMediaslicePicture}
                    >
                        Update
                    </Button>
                    </DialogActions>
                </Dialog> 
        </Grid>
    )
}

export default ImageEditor