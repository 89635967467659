import React from 'react'
import {useForm} from 'react-hook-form'
import {  Grid } from '@mui/material';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import apit from '../api/authApi';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    field: {
        "&&": {
            marginTop: 15,
            marginBottom: 15,
          },
        width: '100%',
        display: "block",
        },
    errorRoot: {
        position: "relative",
    },
    errorMsg: {
        position: "absolute",
        bottom: "-1.3em",
    },
    btn: {
        fontSize: 20,
        backroundColor: '#E3F1E9',
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#60B082',
        },
    }

})
const AddressInput = ({addedAddress}) => {
    const classes = useStyles()
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const submitForm = (body) => {

        console.log(body)

        apit.post(`/api/order/address`,body).then(data => {
            addedAddress(data.data.id)
        })

        reset()

    }

    return (
        
                
            <Container>
                <form 
                    noValidate 
                    autoComplete="off"
                >
                    <Grid 
                container
                // direction="row-reverse"
                justifycontent="center"
                alignitems="center"
            >
                    <Grid item xs={12}>
                    <FormGroup
                        className={classes.errorRoot}
                    >
                        <TextField
                            type="text"
                            {...register("name", { required: true, maxLength: 25} )}
                            className={classes.field}
                            label="Full Name"
                            required
                            error={errors.name}
                        />
                        <div
                            className={classes.errorMsg}
                        >
                            {errors.name?.type === "required" && <p style={{ color: "red" }}><small>Full Name is required</small></p>}
                            {errors.name?.type === "maxLength" && <p style={{ color: "red" }}><small>Full Name  must be less than 25 characters</small></p>}
                        </div> 
                        
                    </FormGroup>
                    </Grid>
                    <Grid item xs={8}>
                    <FormGroup
                        className={classes.errorRoot}
                    >
                        <TextField
                            type="text"
                            className={classes.field}
                            {...register("address1", { required: true})}
                            label="Street address or P.O. Box"
                            variant="outlined"
                            required
                            error={errors.address1}
                        />
                        <div
                            className={classes.errorMsg}
                        >
                            {errors.address1?.type === "required" && <p style={{ color: "red" }}><small>Address is required</small></p>}
                        </div> 
                    </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                    <FormGroup
                        className={classes.errorRoot}
                    >
                        <TextField
                            type="text"
                            className={classes.field}
                            {...register("address2", { required: false, maxLength: 25})}
                            label="Apt, suite, unit, etc."
                            variant="outlined"
                            error={errors.address2}
                        />
                        <div
                            className={classes.errorMsg}
                        >
                            {errors.address2?.type === "maxLength" && <p style={{ color: "red" }}><small>Address 2  must be less than 25 characters</small></p>}
                        </div> 
                    </FormGroup>
                    </Grid>
                    <Grid item xs={7}>
                    <FormGroup
                        className={classes.errorRoot}
                    >
                        <TextField
                            type="text"
                            {...register("city", { required: true, maxLength: 25} )}
                            className={classes.field}
                            label="City"
                            required
                            error={errors.city}
                        />
                        <div
                            className={classes.errorMsg}
                        >
                            {errors.city?.type === "required" && <p style={{ color: "red" }}><small>City is required</small></p>}
                            {errors.city?.type === "maxLength" && <p style={{ color: "red" }}><small>City  must be less than 25 characters</small></p>}
                        </div> 
                        
                    </FormGroup>
                    </Grid>
                    <Grid item xs={2}>
                     <FormGroup
                        className={classes.errorRoot}
                    >
                        <TextField
                            type="text"
                            {...register("state", { required: true, maxLength: 2} )}
                            className={classes.field}
                            label="State"
                            required
                            error={errors.state}
                        />
                        <div
                            className={classes.errorMsg}
                        >
                            {errors.state?.type === "required" && <p style={{ color: "red" }}><small>State is required</small></p>}
                            {errors.state?.type === "maxLength" && <p style={{ color: "red" }}><small>State = 2 characters</small></p>}
                        </div> 
                        
                    </FormGroup>
                    </Grid>
                    <Grid item xs={3}>
                    <FormGroup
                        className={classes.errorRoot}
                    >
                        <TextField
                            type="text"
                            {...register("zip", { required: true, maxLength: 25} )}
                            className={classes.field}
                            label="ZIP Code"
                            required
                            error={errors.zip}
                        />
                        <div
                            className={classes.errorMsg}
                        >
                            {errors.zip?.type === "required" && <p style={{ color: "red" }}><small>ZIP is required</small></p>}
                            {errors.zip?.type === "maxLength" && <p style={{ color: "red" }}><small>ZIP  must be less than 25 characters</small></p>}
                        </div> 
                        
                    </FormGroup>
                    </Grid>
                    <br></br>
                    <Grid item xs={12}>
                    <FormGroup>
                        <Button 
                            sx={{ width: '100%' }}
                            className={classes.btn}
                            variant="contained"
                            onClick={handleSubmit(submitForm)}
                        >
                            Add Address
                        </Button>
                    </FormGroup>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
    }

export default AddressInput