import { useEffect, useState } from 'react'
// import TasksTool from './old/Tasks'
import ListProducts from './company/ListProducts'
import UserList from './admin/UserList'
import useRoles from './auth/useRoles'
import { Grid } from '@mui/material';
import api from './api/companies';
import apit from './api/authApi';
import { viewcompanySet } from '../features/viewcompany';
import { useAppDispatch } from '../app/hooks';

const HomePage = ({SelectProduct,logged=true}) => {
    const [products,setProducts]=useState([]);
    const [search, setSearch]=useState('');
    const [searchResults, setSearchResults]=useState([]);
    const roles = useRoles()
    const dispatch = useAppDispatch()

    useEffect(()=> {
        dispatch(viewcompanySet(0))
        const getCompanyList = async () => {
            
            try {
                api.get(`/api/company/product/active`).then(data => {
                    setProducts(data.data)
                    console.log(data.data)
                }).catch(error => console.log(error));
            } catch (err) {
                console.log(err)
            }
        }
        getCompanyList();
    },[]);

    // useEffect(()=> {
    //     const filteredResults = companies.filter(company =>
    //         ((company.name).toLowerCase()).includes(search.toLowerCase())
    //         || ((company.description).toLowerCase()).includes(search.toLowerCase()));
            
    //         setSearchResults(filteredResults.reverse())
    // },[companies, search]);


    return (
        <div className="home container">
                {/* // <Nav search={search} setSearch={setSearch} /> */}
                <Grid container justifyContent="center" alignItems="center">
                    <ListProducts products={products} SelectProduct={SelectProduct}/>
                </Grid>
            <br></br>
            {/* {(logged && (roles.isAdmin))?
            <div>
                <TasksTool catagory="/"/>
            </div>
            :
                ""
                } */}
            {(logged && (roles.isAdmin))?
            <div>
                <UserList/>
            </div>
            :
                ""
                }
        </div>
    )
}


export default HomePage