import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    loginLoad(state) {
      // it's okay to do this because immer makes it immutable
      // under the hoo
      state.value = 1;
    },
    loginComplete(state) {
      state.value = 0;
    },
    // decrement
    // reset
  },
});

export const { loginLoad, loginComplete } = loaderSlice.actions;
export default loaderSlice.reducer;